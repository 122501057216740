import generateItem from './generateItem';
import { quantitiesPositive, quantityRanges } from '../../config/attributes';
import { getRarityProbabilityRoll } from '../../config/attributes/rarity';
import { lootManifest } from '../../config/loot';

import type { Item } from './generateItem';
import type { Condition, Quantity, Rarity } from '../../config/attributes';
import type { LootCategory, MagicOrMundane } from '../../config/loot';
import type { Dice } from '../dice';

// -- Types --------------------------------------------------------------------

type Container = Item & { contents?: Item[] };

export interface LootSettings {
  categories: LootCategory[];
  conditions: Condition[];
  enableMagicItems: boolean;
  magicItemProbability: number;
  quantity: Quantity | 'random';
  rarities: Rarity[];
}

export type LootResults = {
  containers: Container[];
  items: Item[];
  showCondition: boolean;
} | null;

type LootPoolManifest = Map<Rarity, RarityGroup>;

export type RarityGroup = Map<MagicOrMundane, string[]>;

// -- Public Function ----------------------------------------------------------

/**
 * Generates loot for the given loot settings.
 */
export default function generateLoot(
  dice: Dice,
  settings: LootSettings
): LootResults {
  const quantity = settings.quantity === 'random'
    ? dice.rollArrayItem<Quantity>(quantitiesPositive)
    : settings.quantity;

  if (quantity === 'zero') {
    return null;
  }

  const containers: Item[] = [];
  const items: Record<string, Item> = {};

  const itemCount = dice.rollRange(quantityRanges[quantity]);
  const lootPoolManifest = getLootPoolManifest(settings);

  if (!lootPoolManifest.size) {
    return null;
  }

  const rollRarity = getRarityProbabilityRoll(dice, [ ...lootPoolManifest.keys() ]);

  for (let i = 0; i < itemCount; i++) {
    const rarity = rollRarity();
    const rarityGroup = lootPoolManifest.get(rarity);

    /* v8 ignore next 4 */
    if (!rarityGroup) {
      console.error(`Rarity "${rarity}" is not included in the loot pool manifest in generateItem()`);
      continue;
    }

    const item = generateItem(
      dice,
      settings,
      rarityGroup
    );

    if (items[item.name]) {
      items[item.name].count++;
      continue;
    }

    items[item.name] = item;
  }

  return {
    containers,
    items: Object.values(items),
    showCondition: settings.conditions.length > 0,
  };
}

// -- Private Functions --------------------------------------------------------

/**
 * Normalizes loot categories across rarities for the given settings and returns
 * a loot pool manifest of loot configs.
 */
function getLootPoolManifest(settings: LootSettings): LootPoolManifest {
  const { categories, enableMagicItems, rarities } = settings;
  const itemPoolByRarity: Map<Rarity, Map<MagicOrMundane, string[]>> = new Map();

  for (const category of categories) {
    for (const rarity of rarities) {
      const entry = lootManifest.taxonomy.get(category)?.get(rarity);

      if (!entry) {
        continue;
      }

      if (!itemPoolByRarity.get(rarity)) {
        itemPoolByRarity.set(rarity, new Map());
      }

      const magicItems = entry?.get('magic');
      const mundaneItems = entry?.get('mundane');

      if (enableMagicItems && magicItems) {
        if (!itemPoolByRarity.get(rarity)?.get('magic')) {
          itemPoolByRarity.get(rarity)?.set('magic', []);
        }

        itemPoolByRarity.get(rarity)?.get('magic')?.push(...magicItems);
      }

      if (mundaneItems) {
        if (!itemPoolByRarity.get(rarity)?.get('mundane')) {
          itemPoolByRarity.get(rarity)?.set('mundane', []);
        }

        itemPoolByRarity.get(rarity)?.get('mundane')?.push(...mundaneItems);
      }
    }
  }

  return itemPoolByRarity;
}
