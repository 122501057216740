import { useReducer } from 'react';

import { CONNECTION, connections } from '../../../lib/matrix';

import type { MapSettings } from '../../../lib/generate/generateMap';

// -- Types --------------------------------------------------------------------

export type MapSettingsAction = Partial<MapSettings>;

export type MapSettingsState = [
  settings: MapSettings,
  onSettingChange: (action: MapSettingsAction) => void,
];

// -- Config -------------------------------------------------------------------

/**
 * Available connection type selections.
 */
export const availableConnections: CONNECTION[] = [ ...connections ];

const defaultSettings: MapSettings = {
  complexity: 5,
  connectionFrequency: 2,
  connections: [ ...availableConnections ],
  title: '',
};

// -- Public Hook --------------------------------------------------------------

/**
 * Map settings state hook.
 */
export default function useMapSettings({
  initialSettings = defaultSettings,
}: {
  initialSettings?: MapSettings;
} = {}): MapSettingsState {
  const [ settings, onSettingChange ] = useReducer(settingsReducer, initialSettings);

  return [
    settings,
    onSettingChange, // TODO plural onSettingsChange for all settings hooks
  ];
}

// -- Private Functions --------------------------------------------------------

/**
 * Settings reducer.
 */
function settingsReducer(state: MapSettings, action: MapSettingsAction): MapSettings {
  return {
    ...state,
    ...action,
  };
}
