import { Typography } from '@mui/material';

import styles from './Footer.module.css';
import { appVersion, brandName } from '../../config';
import Link from '../Interface/Link';

// -- Config -------------------------------------------------------------------

/** Footer font size. */
const fontSize = '0.825rem';

/** Current year. */
const year = new Date().getFullYear();

// -- Public Component ---------------------------------------------------------

/**
 * Renders the application footer bar.
 */
export default function Footer({ className }: { className: string }) {
  return (
    <footer
      className={className}
      role="contentinfo"
    >
      <Typography
        component="small"
        fontSize={fontSize}
      >
        <span>© {brandName}, {year}</span>
        <Separator />
        <span>Beta v{appVersion}</span>
      </Typography>

      <Typography
        component="nav"
        display="flex"
        fontSize={fontSize}
      >
        <Link to="/support">Support the Generator <span className={styles.heartEmoji}>❤️</span></Link>

        <Separator />

        <Link to="/release-notes">Release Notes</Link>

        <Separator />

        <Link to="/about">About</Link>

        <Separator />

        <Link href="https://www.mysticwaffle.com/privacy-policy">
          Privacy
        </Link>
      </Typography>
    </footer>
  );
}

// -- Private Components -------------------------------------------------------

/** Renders a nav separator. */
function Separator() {
  return (
    <span aria-hidden data-separator>•</span>
  );
}
