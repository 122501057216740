import { useReducer } from 'react';

import { areas } from '../../../config/area';
import { conditions, sizes } from '../../../config/attributes';
import { DETAIL, details } from '../../../lib/matrix';

import type { AreaSettings } from '../../../lib/generate/generateArea';

// -- Types --------------------------------------------------------------------

export type AreaSettingsAction = Partial<AreaSettings>;

export type AreaSettingsState = [
  settings: AreaSettings,
  onSettingChange: (action: AreaSettingsAction) => void,
];

// -- Config -------------------------------------------------------------------

/**
 * Available detail selections.
 *
 * Excludes the "Trap" detail from the area generator options.
 */
export const availableDetails: DETAIL[] = [ ...details ].filter((detail) => detail !== DETAIL.Trap);

const defaultSettings: AreaSettings = {
  areas: [ ...areas ],
  conditions: [ ...conditions ],
  details: [ ...availableDetails ],
  sizes: [ ...sizes ],
  trapFrequency: 1,
  trapProbability: 15,
};

// -- Public Hook --------------------------------------------------------------

/**
 * Area settings state hook.
 */
export default function useAreaSettings({
  initialSettings = defaultSettings,
}: {
  initialSettings?: AreaSettings;
} = {}): AreaSettingsState {
  const [ settings, onSettingChange ] = useReducer(settingsReducer, initialSettings);

  return [
    settings,
    onSettingChange,
  ];
}

// -- Private Functions --------------------------------------------------------

/**
 * Loot settings reducer.
 */
function settingsReducer(state: AreaSettings, action: AreaSettingsAction): AreaSettings {
  return {
    ...state,
    ...action,
  };
}
