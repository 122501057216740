import {
  getRectangleCoordinates,
} from './utility';

import type {
  Coordinates,
  Dimensions,
  DirectionCardinal,
} from './';

// -- Types --------------------------------------------------------------------

export interface MatrixShapeWalls {
  /* eslint-disable typescript-sort-keys/interface */
  north: Coordinates[];
  east: Coordinates[];
  south: Coordinates[];
  west: Coordinates[];
  /* eslint-enable typescript-sort-keys/interface */
}

// -- Public Functions ---------------------------------------------------------

/**
 * A rectangular matrix shape.
 */
export class Rectangle {

  private x: number;
  private y: number;
  private width: number;
  private height: number;

  /** Rectangle constructor. */
  constructor([ width, height ]: Dimensions) {
    this.x = 0;
    this.y = 0;
    this.width = width - 1;
    this.height = height - 1;
  }

  /** Returns coordinates for the rectangle. */
  get coordinates(): Coordinates[] {
    return getRectangleCoordinates([ this.x, this.y ], [ this.x + this.width, this.y + this.height ]);
  }

  /** The rectangle's exterior corner coordinates. */
  get corners(): Coordinates[] {
    return [
      [ this.x - 1, this.y - 1 ],
      [ this.x + this.width + 1, this.y - 1 ],
      [ this.x + this.width + 1, this.y + this.height + 1 ],
      [ this.x - 1, this.y + this.height + 1 ],
    ];
  }

  /** The rectangle's exterior wall coordinates, keyed by direction. */
  get walls(): MatrixShapeWalls {
    return {
      /* eslint-disable sort-keys */
      north: this.getWall('north'),
      east: this.getWall('east'),
      south: this.getWall('south'),
      west: this.getWall('west'),
      /* eslint-enable sort-keys */
    };
  }

  /** A single wall for the rectangle in the given direction. */
  public getWall(direction: DirectionCardinal): Coordinates[] {
    switch (direction) {
      case 'north':
        return getRectangleCoordinates([ this.x, this.y - 1 ], [ this.x + this.width, this.y - 1 ]);
      case 'east':
        return getRectangleCoordinates([ this.x + this.width + 1, this.y ], [ this.x + this.width + 1, this.y + this.height ]);
      case 'south':
        return getRectangleCoordinates([ this.x, this.y + this.height + 1 ], [ this.x + this.width, this.y + this.height + 1 ]);
      case 'west':
        return getRectangleCoordinates([ this.x - 1, this.y ], [ this.x - 1, this.y + this.height ]);
    }
  }

  /**
   * Sets the rectangle's origin coordinates (the upper left cell), accounting
   * for an optional offset, such as the relative coordinates of a wall cell.
   */
  public setRelativeOrigin([ x, y ]: Coordinates, [ offsetX, offsetY ]: Coordinates = [ 0, 0 ]) {
    this.x = x - offsetX;
    this.y = y - offsetY;
  }

}
