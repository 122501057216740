import { Button, Stack } from '@mui/material';
import { memo, useContext } from 'react';

import useLootPageGenerator from './hooks/useLootPageGenerator';
import styles from './styles.module.css';
import TableOfContents from './TableOfContents';
import CenterContent from '../../components/Display/CenterContent';
import EmptyMessage from '../../components/Display/EmptyMessage';
import FancyBox from '../../components/Display/FancyBox';
import { ArrowRight as ArrowRightIcon, Bottle as BottleIcon } from '../../components/Display/Icons';
import WidowFix from '../../components/Display/WidowFix';
import ActionBar from '../../components/Interface/ActionBar';
import BigJuicyButton from '../../components/Interface/BigJuicyButton';
import EmptyState from '../../components/Interface/EmptyState';
import GeneratorPanel from '../../components/Interface/GeneratorPanel';
import useGeneratorPanelState from '../../components/Interface/GeneratorPanel/hooks/useGeneratorPanelState';
import Layout from '../../components/Layout';
import useSidebar from '../../components/Layout/hooks/useSidebar';
import LootInputs from '../../components/Loot/LootInputs';
import LootTable from '../../components/Loot/LootTable';
import Metadata from '../../components/Utility/Metadata';
import { UnsavedChangesContext } from '../../components/Utility/UnsavedChangesContextProvider';

import type { SeedState } from '../../components/Interface/GeneratorPanel/hooks/useSeedState';
import type { LootSettingsAction } from '../../components/Loot/hooks/useLootSettings';
import type { LootResults, LootSettings } from '../../lib/generate/generateLoot';

// -- Config -------------------------------------------------------------------

/** Table of contents button styles. */
const tableOfContentsButtonSx = { mb: 2 };

// -- Memoized Components ------------------------------------------------------

const ResultsMemo = memo(Results);
const LootPageTableOfContentsMemo = memo(LootPageTableOfContents);

// -- Public Component ---------------------------------------------------------

/**
 * Renders the loot generator page.
 */
export default function LootPage() {
  const { onHideSidebar, onShowSidebar, showSidebar } = useSidebar();
  const { clearChangesFlag, flagChanges } = useContext(UnsavedChangesContext);

  const {
    mode,
    onClear,
    onGenerate,
    onSeedChange,
    onSettingChange,
    onToggleToc,
    results,
    seed,
    settings,
  } = useLootPageGenerator({
    clearChangesFlag,
    onComplete: () => {
      onHideSidebar?.();
      flagChanges();
    },
  });

  const layoutProps = {
    onGenerate,
    onSeedChange,
    onSettingChange,
    seed,
    settings,
    showSidebar,
  };

  if (mode === 'toc') {
    return (
      <LootPageLayout {...layoutProps}>
        <LootPageTableOfContentsMemo onClear={onToggleToc} />
      </LootPageLayout>
    );
  }

  if (results) {
    return (
      <LootPageLayout {...layoutProps}>
        <ResultsMemo
          onClear={onClear}
          onShowSidebar={onShowSidebar}
          {...results}
        />
      </LootPageLayout>
    );
  }

  if (results === null) {
    return (
      <LootPageLayout {...layoutProps}>
        <EmptyResults onShowSidebar={onShowSidebar} />
      </LootPageLayout>
    );
  }

  return (
    <LootPageLayout {...layoutProps}>
      <EmptyGenerator
        onGenerate={onShowSidebar || onGenerate}
        onToggleToc={onToggleToc}
      />
    </LootPageLayout>
  );
}

// -- Private Components -------------------------------------------------------

/**
 * Renders the loot page layout.
 */
function LootPageLayout({
  children,
  onGenerate,
  onSeedChange,
  onSettingChange,
  seed,
  settings,
  showSidebar,
}: {
  children: React.ReactNode;
  onGenerate: () => void;
  onSettingChange: (action: LootSettingsAction) => void;
  settings: LootSettings;
  showSidebar: boolean;
} & SeedState) {
  return (
    <>
      <Metadata
        description="Generate loot and treasure for your D&D/TTRPG campaign; weapons, armor, coins, gems, art objects, magic items, and more."
        title="D&D Loot Generator"
      />

      <Layout
        sidebar={showSidebar
          ? (
            <GeneratorOptions
              onGenerate={onGenerate}
              onSeedChange={onSeedChange}
              onSettingChange={onSettingChange}
              seed={seed}
              settings={settings}
            />
          ) : undefined}
      >
        {children}
      </Layout>
    </>
  );
}

/**
 * Renders loot page table of contents.
 */
function LootPageTableOfContents({ onClear }: { onClear: () => void }) {
  return (
    <div className={styles.contentLayout}>
      <ActionBar>
        <Button
          onClick={onClear}
          size="small"
          variant="outlined"
        >
          Clear
        </Button>

        {/* <Button TODO
          onClick={onDownloadLoot}
          size="small"
          variant="outlined"
        >
          Download
        </Button> */}
      </ActionBar>

      <CenterContent>
        <FancyBox
          className={styles.resultsBox}
          title="Loot Table of Contents"
        >
          <TableOfContents />
        </FancyBox>
      </CenterContent>
    </div>
  );
}

/**
 * Renders a generate loot button when no loot has been generated.
 */
function EmptyGenerator({
  onGenerate,
  onToggleToc,
}: {
  onGenerate: () => void;
  onToggleToc: () => void;
}) {
  return (
    <EmptyState
      description={
        <WidowFix>
          What adventurer doesn’t love loot? Generate some treasure for your next campaign!
        </WidowFix>
      }
      title="Loot Generator"
    >
      <Stack
        direction="row"
        mb={4}
        mt={4}
        spacing={4}
      >
        <BigJuicyButton
          icon={BottleIcon}
          label="Generate Loot"
          onClick={onGenerate}
        />
      </Stack>

      <div className="hide-sm">
        <Button
          onClick={onToggleToc}
          sx={tableOfContentsButtonSx}
        >
          Loot Table of Contents
        </Button>
      </div>

      <p className="paragraph muted">
        Configure loot settings, then click <em className="emphasize">Generate</em>.
      </p>
    </EmptyState>
  );
}

/**
 * Renders an empty message when no loot can be generated.
 */
function EmptyResults({ onShowSidebar }: { onShowSidebar?: () => void }) {
  return (
    <CenterContent>
      <EmptyMessage
        maxWidth={true}
        message={[
          'There is no loot for the currently selected categories and rarities. Select additional categories and/or rarities then click "Generate".',
        ]}
        title="No loot here…"
      >
        {onShowSidebar &&
          <Button
            onClick={onShowSidebar}
            size="large"
            variant="outlined"
          >
            Generator Options
          </Button>
        }
      </EmptyMessage>
    </CenterContent>
  );
}

/**
 * Renders the loot results table.
 */
function Results({
  items,
  onClear,
  onShowSidebar,
  showCondition,
}: NonNullable<LootResults> & {
  onClear: () => void;
  onShowSidebar?: () => void;
}) {
  return (
    <div className={styles.contentLayout}>
      <ActionBar
        action={onShowSidebar
          ? (
            <Button
              onClick={() => {
                onClear();
                onShowSidebar();
              }}
              size="small"
              startIcon={<ArrowRightIcon aria-hidden rotate={180} />}
              variant="text"
            >
              Options
            </Button>
          ) : undefined
        }
      >
        <Button
          onClick={onClear}
          size="small"
          variant="outlined"
        >
          Clear
        </Button>

        {/* <Button TODO
          onClick={onDownloadLoot}
          size="small"
          variant="outlined"
        >
          Download
        </Button> */}
      </ActionBar>

      <CenterContent>
        <FancyBox
          className={styles.resultsBox}
          title="Loot Results"
        >
          <LootTable
            items={items}
            label="Loot Results"
            showCondition={showCondition}
          />
        </FancyBox>
      </CenterContent>
    </div>
  );
}

/**
 * Renders the loot generator options.
 */
function GeneratorOptions({
  onGenerate,
  onSeedChange,
  onSettingChange,
  seed,
  settings,
}: {
  onGenerate: () => void;
  onSettingChange: (action: LootSettingsAction) => void;
  settings: LootSettings;
} & SeedState) {
  const generatorPanelState = useGeneratorPanelState();
  const { showInfo } = generatorPanelState;

  return (
    <GeneratorPanel
      aria-label="Loot generator settings"
      onGenerate={onGenerate}
      onSeedChange={onSeedChange}
      seed={seed}
      showAdvancedDivider
      {...generatorPanelState}
    >
      <LootInputs
        onSettingChange={onSettingChange}
        paddingTop={0}
        showInfo={showInfo}
        {...settings}
      />
    </GeneratorPanel>
  );
}
